<template>
  <div>
    <router-link :to="{ name: 'new-contact-question' }" class="btn btn-primary mb-4">
      <i class="bi bi-plus-lg me-1 mb-1"></i> Add question
    </router-link>
    <!--begin::Table-->
    <vue-good-table
      mode="remote"
      :total-rows="totalRecords"
      :is-loading="isTableLoading"
      :pagination-options="{ enabled: true }"
      :sort-options="{ enabled: false, initialSortBy: serverParams.sort }"
      :rows="rows"
      :columns="columns"
      :fixed-header="true"
      @page-change="onPageChange"
      @sort-change="onSortChange"
      @column-filter="onColumnFilter"
      @per-page-change="onPerPageChange"
      @row-click="toContactQuestion"
      styleClass="custom-table clickable"
    >
      <template #loading-content class="text-center">Loading...</template>
      <template #emptystate class="text-center">No entry found</template>
      <template #table-row="props">
        <!-- Column: Common -->
        <span>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template #pagination-bottom="props">
        <div class="d-flex justify-content-between flex-wrap align-items-center">
          <div class="d-flex align-items-center mb-0 mt-1">
            <select
              class="form-control form-control-sm me-3"
              :value="serverParams.perPage"
              @change="(e) => props.perPageChanged({ currentPerPage: e.target.value })"
            >
              <option
                v-for="(option, idx) in ['5', '10', '25', '50', '100']"
                :key="`select-perpage-${idx}`"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
            <span class="text-nowrap">
              questions per page
            </span>
          </div>
          <div>
            <ul class="pagination">
              <li
                class="page-item previous"
                :class="{ disabled: serverParams.page === 1 }"
              >
                <a @click.prevent="props.pageChanged({ currentPage: serverParams.page - 1 })" href="#" class="page-link"><i class="previous"></i></a>
              </li>
              <div
                v-for="idx in Math.ceil(props.total / serverParams.perPage)"
                class="d-flex flex-row align-items-center"
                :key="idx"
              >
                <li
                  class="page-item"
                  :class="{ active: serverParams.page === idx }"
                >
                  <a
                    @click.prevent="props.pageChanged({ currentPage: idx })"
                    href="#"
                    class="page-link"
                    v-if="
                      idx <= 3 ||
                      (idx >= serverParams.page - 1 &&
                        idx <= serverParams.page + 1) ||
                      idx >= Math.ceil(props.total / serverParams.perPage) - 2
                    "
                    >{{ idx }}</a
                  >
                </li>
                <p
                  class="mb-0 mt-1 mx-3"
                  v-if="
                    (idx === serverParams.page - 2 && idx > 3) ||
                    (idx === serverParams.page + 2 &&
                      idx < Math.ceil(props.total / serverParams.perPage) - 2)
                  "
                >
                  ...
                </p>
              </div>
              <li
                class="page-item next"
                :class="{ disabled: serverParams.page === Math.ceil(props.total/serverParams.perPage) }"
              >
                <a @click.prevent="props.pageChanged({ currentPage: serverParams.page + 1 })" href="#" class="page-link"><i class="next"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!--end::Table-->
  </div>
</template>

<script>
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default {
  name: 'ContactQuestionsManager',
  data() {
    return {
    //   isNewTemplateModalOpen: false,
      isTableLoading: false,
      totalRecords: 0,
      columns: [
        {
          label: "Title",
          field: "title",
          sortable: true,
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: "Require clinic",
          field: "requestClinic",
          formatFn: e => e ? 'Yes' : 'No',
          sortable: true,
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: [
                { value: 'true', text: 'Yes' },
                { value: 'false', text: 'No' },
            ],
          },
        },
        {
          label: "Prospect type",
          field: "hotProspect",
          formatFn: e => e ? 'Hot prospect' : 'Cold prospect',
          sortable: true,
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: [
                { value: 'true', text: 'Hot' },
                { value: 'false', text: 'Cold' },
            ],
          },
        },
        {
          label: "Auto reply template",
          field: "autoResponseEmailTemplate",
          formatFn: template => template ? template.title : 'No auto reply',
        },
      ],
      rows: [],
      serverParams: {
        columnFilters: {},
        sort: {
          field: "createdAt",
          type: "desc",
        },
        page: 1,
        perPage: 25,
      },
    };
  },
  mounted() {
    this.fetchItemsFromApi();
    setCurrentPageBreadcrumbs("Contact Questions", []);
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps };
    },
    onPageChange(params) {
      console.log('onPageChange', params);
      this.updateParams({ page: params.currentPage });
      this.fetchItemsFromApi();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.onPageChange({ currentPage: 1 });
    },
    onSortChange([params]) {
      this.updateParams({
        sort: {
          field: params.field,
          type: params.type,
        },
      });
      this.onPageChange({ currentPage: 1 });
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.onPageChange({ currentPage: 1 });
    },
    /**
     * Fetch models from API and display them in the table
     */
    fetchItemsFromApi() {
      this.isTableLoading = true;

      const tableSearch = {};
      console.log(this.serverParams.columnFilters);
      Object.keys(this.serverParams.columnFilters).forEach((e) => {
        if (!this.serverParams.columnFilters[e]) return;
        if (['requestClinic'].includes(e)) {
            tableSearch[e] = this.serverParams.columnFilters[e];
        } else {
            tableSearch[`${e}_contains`] = this.serverParams.columnFilters[e];
        }
      });

      const getApiParams = {
        ...tableSearch,
        limit: this.serverParams.perPage,
        page: this.serverParams.page,
        sortBy: `${this.serverParams.sort.field}:${this.serverParams.sort.type}`,
        populate: 'autoResponseEmailTemplate',
      };

      ApiService
        .query('/contact-questions', { params: getApiParams })
        .then((res) => {
          this.rows = res.data.results;
          this.totalRecords = res.data.totalResults;
          this.isTableLoading = false;
        })
        .catch((err) => {
          this.isTableLoading = false;
        });
    },
    toContactQuestion(params) {
      this.$router.push(`/contact-questions/${params.row.id}`);
    },
  },
};
</script>